:root {
  --borderColor: #426ba1;
  --lightBorderColor: #ececec;
  --lightBackground: #ffffff;
  --themeColor: #5828b0;
}
body {
}
.App {
  text-align: center;
}
.App.dark {
  background: radial-gradient(ellipse at top, #224980, #1f0e3b),
    radial-gradient(ellipse at bottom, #242053, #1f0e3b);
  color: #eee;
}
.App:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 100%;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.invoice-detail{
  text-align: left;
  font-size: 14px;
  margin-top: 20px;
}
.invoice-heading-new{
  display: flex;
  justify-content: space-between;
}
.invoicedetail{
  padding:10%;
  padding-top: 50px;

}
.invoicedetail .profile{
background-color: #E9EBFF;
padding: 50px;
}
.invoice-address{
  text-align: left;
}
.pheading{
  margin-top: 50px;
  margin-bottom: 30px;
}
.iaction{
  display: flex;
    justify-content: space-evenly;
}
.itable{
  border-collapse: separate;
  border-spacing: 0 1em;
}
.itablee{
  text-align: cender;
  border-collapse: separate;
  border-spacing: 1em 1em;
}
.profile .btn-secondry{
  background-color: #ffffff;
  font-size: 20px;
}
.profile .btn-primary{
  font-size: 20px;
}
.ttotal p{
  background-color: #fff;
  padding: 10px;
  font-size: 20px;
}
.form-action{
  justify-content: center;
}
.invoice-form{
  background-color: #E9EBFF;
  padding: 10px;
}
.pending{
  background-color: #FFC700;
  padding: 8px;
  border-radius: 10px;
}
 .paid{
  background-color: #87FF00;
  padding: 8px;
  border-radius: 10px;
 }
 .overdue{
  background-color: #FF0000;
  padding: 8px;
  border-radius: 10px;
 }
 ul.nav.nav-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
}
.invoice-heading{
  text-align: left;
  font-size: 20px;
  margin-left: 5%;
}
 /* Tab Container */
.invice-tab {
  width: 60%;
  height: auto;
  background: #F3F3F3;
  margin: 3.5rem auto 1.5rem;
  color: #E8F0F2;
  border-radius: 2rem;
 }
 
 ul.invice-tab.nav-tabs .nav-link{
  color: #000000;
  border: none;
 }
 ul.invice-tab.nav-tabs .nav-link.active{
   background:#5A33B2;
   color:#ffffff;
   border-radius: 20px;
 }
 .thead{
  background-color: #F3F3F3;
 }
.thead th{
  text-transform: uppercase;
}
 ul.invice-tab.nav.nav-tabs{
  justify-content: space-between;
 }

.invoice .profile,.itemtable{
  display: flex;
  justify-content: space-between;
  text-align: justify;
}
.invoice .title{
    color: #3c4142;
    font-size: 18px;
    font-weight: bold;
}
.invoice .text{
  color: #3c4142;
  font-size: 16px;
}
.theading th{
  border-top: 1px solid #CCD1FF;
  border-bottom: 1px solid #CCD1FF;
  height: 40px;
}
.trow{
  background-color: #ffffff;
  height: 40px;
}
.invoice .address{
  margin-top: 50px;
}
.invoicedetail  .address{
  margin-top: 50px;
}
.invoicedetail .qrcode{
  margin-top: 30px;
  text-align: left;
}
.tright{
  text-align: right;
}

.top-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  flex-wrap: wrap;
  background: #5828B0;
}
.sub-section{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
}
.mtab .nav-link {
  background-color: var(--themeColor) !important;
  border-radius: 0.25rem !important;
  border-color: var(--themeColor) !important;
}
.heading-section{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px;
  background:linear-gradient(180deg, #5828B0 0%, rgba(88, 40, 176, 0) 222.45%);
}

.login label {
  display: block;
  margin-top: 10px;
  float: left;
}

.login .card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.login  .card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.login  .profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}


.heading-section span{
  color: #ffffff;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.loginsubmitbutton{
  width: 100%;
}
.news-title{
  text-align: left;
}
.fdDisplay{
 display:flex;
}
.address-text{
  margin-top: 20px;
  color: #798294;
  max-width: 400px;
}
.preview{
  padding: 20px;
  background: rgba(88, 40, 176, 0.04);
}
.preview img{
  width: 100%;
  padding: 20px;
}
.loginModal .modal-body{
height: auto;
}
.footer{
  display: flex;
  margin-top: 50px;
  padding: 20px;
  background: mintcream;
}
.news-text a{
  color:#333333;
  text-decoration: none;
  text-align: left;
  padding: 20px;
}
.news-text h6 {
  font-size: 1.2rem;
}
.footer-one{
  text-align: start;
}
.footer-two{
  display: flex;
  text-align: left;
  justify-content: space-between;
}
.footer-two ul{
  padding-left: 0px;
  list-style: none;
}
.footer-two ul li {
  line-height: 35px;
  position: relative;
}
.footer-two 
  a {
    font-weight: 500;
    font-size: 14px;
    color: rgb(88, 102, 126);
    text-decoration: none;
}
.copyright{
  color:#40444B;
  font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 22px;
}
.login-button{
  background: #5928B2;
  color: #ffffff;
  border-radius: 12px;
  width: 131px;
}
.subheading{
  text-align: left;
  font-style: normal;
font-weight: 600;
font-size: 26px;
line-height: 31px;
color: #000000;
margin-bottom: 20px;
}
.subcard{
  background: #5828B0!important;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 34px;
color: #fff!important;
}
.currencystats{
  background: rgba(88, 40, 176, 0.04);
}
.exchange{
  background: #5828B0;
  padding: 20px;
  display: flex;
  overflow: auto;
  justify-content: space-between;
}
.exchange .mcard{
  background: #fff;
  padding: 5px;
  margin: 5px;
  color: #000;
}
.heading{
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
    padding-bottom: 18px;
    padding-top: 5px;
}
.modal-body{
  overflow: scroll;
  height: 70vh;
}
.bstats{
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
.subcard a{
  color:#ffffff!important
}
.mtitle{
  width: 100%;
  text-align: center;
}
.coin-row{
  justify-content: space-between;
  margin-top: 15px;
}
.crypto-icon-exchange{
  width: 50px;
  margin-right: 20px;
}
.statistics {
  display: flex;
  justify-content: center;
  gap: 60px;
  flex-wrap: wrap;
  padding: 20px;
}
.statistics .token-value-item {
  display: flex;
  justify-content: space-between;
  min-width: 330px;
}
.statistics .statistics-title {
  font-size: 25px;
  text-align: left;
}
.version {
  position: absolute;
  top: 10px;
  right: 20px;
}
.version-info {
  font-size: 10px;
  padding: 5px 10px;
  background: #ffffff33;
  color: #000000;
  border-radius: 10px;
}
.asteroid1,
.asteroid2,
.asteroid3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0.2;
  animation-name: rotateAsteroids;
  animation-duration: 65s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transform-origin: center;
  display: none;
}
.asteroid1 {
  background: url("./component/asteroids1.png") no-repeat;
  width: 380px;
  height: 380px;
}
.asteroid2 {
  background: url("./component/asteroids2.png") no-repeat;
  width: 567px;
  height: 562px;
  animation-duration: 85s;
}
.asteroid3 {
  background: url("./component/asteroids3.png") no-repeat;
  width: 683px;
  height: 680px;
  animation-duration: 225s;
}
@keyframes rotateAsteroids {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.header-logo {
  text-align: left;
  margin-bottom: 40px;
}
.card {
  background: transparent;
  border-radius: 20px;
  box-shadow: 0 0 5px #00000011;
  border: 1px solid var(--lightBorderColor);
  background: #ffffff99;
}
.App.dark .card {
  border: 1px solid var(--borderColor);
  background: transparent;
}
.card.comparisonTable {
  padding: 20px 0;
  overflow-y: hidden;
  padding-bottom: 30px;
}
.news-wrapper::-webkit-scrollbar,
.comparisonTable::-webkit-scrollbar {
  background: transparent;
  height: 10px;
  width: 10px;
  border-radius: 10px;
}
.news-wrapper::-webkit-scrollbar-thumb,
.comparisonTable::-webkit-scrollbar-thumb {
  background: #00000077;
  border-radius: 10px;
}
.coin-price {
  min-width: 9rem;
  padding: 15px 0;
}
.exchange-row {
  display: flex;
  border-bottom: 1px solid #426ba144;
  margin: 20px 0;
}
.exchange-name {
  min-width: 9rem;
}
.exchange-name img {
  width: 30px;
  margin-bottom: 10px;
  max-height: 25px;
}
table.comparisonTable th {
  font-weight: normal;
}
table.comparisonTable th,
table.comparisonTable td {
  text-align: left;
  padding: 10px 0;
  min-width: 9rem;
  text-align: center;
}
table.comparisonTable th:first-child,
table.comparisonTable td:first-child {
  min-width: 2rem;
}
table.comparisonTable th:first-child,
table.comparisonTable td:first-child {
  padding-left: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
table.comparisonTable th:last-child,
table.comparisonTable td:last-child {
  padding-right: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
table.comparisonTable tr:hover td {
  background: #00000007;
}
table.comparisonTable .exchange-logo {
  width: 30px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.card .card-text {
  font-size: 30px;
  font-weight: 700;
}
.card .card-title {
  font-weight: normal;
}
.card.news-wrapper {
  padding: 20px;
  max-height: 895px;
  overflow-y: scroll;
}
.card.news-wrapper .news-title {
  font-size: 1.1rem;
}
.card.news-wrapper .news-item {
  display: flex;
  margin-bottom: 20px;
}
.card.news-wrapper .news-item:last-child {
  margin-bottom: 0;
}
.card.news-wrapper .news-item .news-image {
  width: 120px;
  height: 120px;
  margin-right: 10px;
  border-radius: 10px;
}
.card.news-wrapper .news-item .news-image img {
  width: 120px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.card.news-wrapper .news-item .news-text a {
  text-decoration: none;
  color: #333333;
}
.App.dark .card.news-wrapper .news-item .news-text a {
  color: #fff;
}
.card.news-wrapper .news-item .news-text a > * {
  text-align: left;
}
.card.news-wrapper .input-group-text {
  background-color: var(--lightBackground);
  border: 1px solid var(--lightBorderColor);
  border-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
  padding: 14px;
  color: #333;
}
.App.dark .card.news-wrapper .input-group-text {
  color: #ffffffaa;
  background-color: #00000044;
  border: 1px solid var(--borderColor);
}
.card.news-wrapper .search-field {
  margin-bottom: 20px;
  border-radius: 10px;
  outline: none;
  border: 1px solid var(--lightBorderColor);
  color: #333;
  padding: 10px 20px;
  background: #edf2f5;
  border-left: 0;
  width: 88%;
}
.App.dark .card.news-wrapper .search-field {
  border: 1px solid var(--borderColor);
  color: #fff;
  padding: 10px 20px;
  background: #00000044;
}
.news-meta {
  font-size: 12px;
}
.news-meta img {
  width: 20px;
  border-radius: 50px;
  background: #000000;
}
.nav-tabs {
  border-bottom: 0;
}
.nav-tabs li {
  margin-right: 10px;
}
.nav-tabs .nav-link {
  border-radius: 10px;
  color: #fff;
  border-color: #096caf;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #ffffff;
  background: linear-gradient(90deg, #05a2e9, #096caf);
  border-radius: 10px;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-radius: 10px;
  background-color: #05a2e9;
  border-color: #096caf;
}
.navbar .nav-link {
  color: #ffffff;
}
.dasbutton{
  margin-right: 20px;
}
.crypto-name {
  border-radius: 10px;
}
.crypto-icon {
  width: 30px;
  margin-right: 5px;
}
.crypto-row {
  display: flex;
  border: 1px solid transparent;
  border-radius: 10px;
  transition: 250ms ease-in-out;
}
.crypto-row:hover {
  border-color: transparent;
  box-shadow: none;
  background-color: #eaf2ff;
}
.App.dark .crypto-row:hover {
  border-color: #426ba144;
  background-color: #00000011;
  box-shadow: 0 0 10px #00000010;
}
.text-left {
  text-align: left;
}
.table > :not(caption) > * > * {
  border: none;
}
.btn-primary,
.btn-primary:hover {
  background-color: var(--themeColor);
  border-color: var(--themeColor);
}
.App.dark .btn-primary {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: none;
}
/**********Switch CSS ***************/
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 5px;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  height: 26px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--themeColor);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--themeColor);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/****** End of Switch CSS ************/
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (min-width: 769px) {
  .col-md-4 {
    width: 16.6666%;
  }
}
@media screen and (max-width: 768px) {
  .col-sm-12,
  .col-md-4 {
    width: 100%;
    margin-bottom: 10px;
  }
  .col {
    flex: none;
  }
  .card.news-wrapper .search-field {
    width: 85%;
  }
  .card.news-wrapper .news-item {
    flex-wrap: wrap;
  }
  .card.news-wrapper .news-item .news-image {
    margin-bottom: 10px;
  }
  .statistics {
    justify-content: center;
  }
  .statistics .statistics-title {
    text-align: center;
  }
  .tableExtender {
    display: none;
  }
}
